import React, {useState} from 'react';
import PropTypes from "prop-types"

import imgselectarrow from '../../images/controls/select-arrow.png'

import * as styles from "./select.module.css"


const ControlSelect = ({list, defaultvalue, handleChange, textNone, disabled}) => {
	const [displayvalue, setDisplayvalue] = useState("");
	const [selectvalue, setSelectvalue] = useState(defaultvalue);

	const onValueChange = (event) => {
		let index = event.nativeEvent.target.selectedIndex;
		//console.log(event.target.value);
		setDisplayvalue(event.nativeEvent.target[index].text);
		setSelectvalue(event.target.value);
		if (typeof handleChange !== "undefined") {
			handleChange(event.target.value)
		}
	}
	return(
		<div className={"selectbox "+styles.selectbox}>
			{disabled?
				<>{" "+displayvalue}</>
			: 
				<>
					<select value={selectvalue} className={styles.selectboxinput} onBlur={onValueChange} onChange={onValueChange}>
						{textNone !== "" && 
							<option key={"noneselected"} value={""}>{textNone}&nbsp;&nbsp;&nbsp;&nbsp;</option>				
						}
						{list.map((data, index) => {
							var tmpkey = data.code;
							if (data.hasOwnProperty("key")) {
								tmpkey = data.key;
							}
							return <option key={tmpkey} value={data.code}>{data.name}&nbsp;&nbsp;&nbsp;&nbsp;</option>
						})}
					</select>				
					<img alt='' src={imgselectarrow} className={styles.selectboxicon}/>
				</>
			}
		</div>
	)
}


ControlSelect.propTypes = {
	defaultvalue: PropTypes.string,
	textNone: PropTypes.string,
	disabled: PropTypes.bool,
}


ControlSelect.defaultProps = {
	defaultvalue: ``,
	textNone: ``,
	disabled: false,
}


export default ControlSelect;

