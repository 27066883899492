import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import "./style.css"

import logo from "../../images/logo-mobile.png"
import menuclose from "../../images/menu-close.png"


const NavBar = ({type, items, activePath, fullPath, closeHandler, loggedin, suffix, fadeOption, hasMenu})=> {
	const [activeURL, setActiveURL] = useState("");

	return <>
			<ul className={"navbar-nav"+suffix+" "+type + suffix + "-nav navbar-menu-"+fadeOption}>
			{hasMenu && <li className={"navbar-close"+suffix+" "+type + suffix + "-close"}>
				<img src={logo} alt="Menu" className={"navbar-close-logo " +type + suffix + "-close-logo"}/>
				<button className={"navbar-close " +type + suffix + "-close"} onClick={closeHandler}><img src={menuclose} alt="Close" /></button>
			</li>}
			{items.map((entry) =>
				<li className={(activePath===entry.url?"active ":"") + "navbar-nav"+suffix+" "+type + suffix + "-nav"}>
					{hasMenu === false && 
						<Link 
							to={"/"+entry.url}
							className={"font-style-bold navbar-menu"+suffix+" navbar-nav"+suffix+" "+type + suffix + "-nav"+(fullPath === entry.url?" active":"")}
							activeClassName="active"
						>
							<div className={"navbar-nav-aligner"}></div>
								<span className={"navbar-nav-aligner"}>{entry.title}</span>
							<div className={"navbar-nav-aligner"}></div>
						</Link>}
					{hasMenu && !entry.subitems && 
						<button 
							onClick={() => navigate("/"+entry.url) }
							className={"font-style-bold navbar-menu"+suffix+" navbar-button"+suffix+" "+type + suffix + "-button"}
						>
						  {entry.title}
						</button>}
					{hasMenu && entry.subitems && 
						<button 
							onClick={() => setActiveURL(entry.url)}
							className={"font-style-bold navbar-menu"+suffix+" navbar-button"+suffix+" "+type + suffix + "-button"}
						>
						  {entry.title}
						</button>}
					{(entry.subitems && (activeURL===entry.url || activePath===entry.url)) && <NavBar activePath={activePath} fullPath={fullPath} items={entry.subitems} type={type} suffix={suffix + "-sub"} />}
				</li>
			)}
		</ul>
	</>
}

NavBar.propTypes = {
	suffix: PropTypes.string,
	fadeOption: PropTypes.string,
	hasMenu: PropTypes.bool
};

NavBar.defaultProps = {
	hasMenu: false,
	fadeOption: `none`,
	suffix: ``
};


export default NavBar
