import React, { useState } from 'react';
import PropTypes from "prop-types"

import ControlSelect from '../controls/select';


import "../../styles/form.css"

import * as styles from "./style.module.css"

const formatTools = require("../../../lib/formatTools");
const requestJSON = require("../../../lib/requestJSON");

const ContactForm = ({submitURL, fields, sendLabel}) => {
	const [inputkey, setInputkey] = useState("new");
	const [loading, setLoading] = useState(false);
	const [inputdirty, setInputdirty] = useState(false);
	const [inputList, setInputList] = useState(fields);
	const [message, setMessage] = useState("");

	function submitInput(e) {
		var payload = {};
		var idx = 0;
		var list = [...inputList]
		var tmptype = "";
		while (idx < list.length) {
			if (list[idx].hasOwnProperty("required")) {
				if (list[idx].hasOwnProperty("labelvalue")) {
					if (list[idx].labelvalue === "") {
						setMessage("Please provide "+list[idx].label);
						return;
					} else if (list[idx].value === "") {
						setMessage("Please provide "+list[idx].labelvalue);
						return;
					}
				} else if (list[idx].value === "") {
					setMessage("Please provide "+list[idx].label);
					return;
				}
			}
			tmptype = "text";
			if (list[idx].hasOwnProperty("filter")) {
				if (list[idx].filter === "integer") {
					list[idx].value = formatTools.integer(list[idx].value);
					tmptype = "integer";
				}
			}

			if (list[idx].hasOwnProperty("type")) {
				tmptype = list[idx].type;
				if (list[idx].type==="email") {
					if (formatTools.validateEmail(list[idx].value) === false) {
						setMessage("Invalid "+list[idx].label);
						return;
					}
				} else if (list[idx].type==="tel") {
					if (formatTools.validateMobile(list[idx].value) === false) {
						setMessage("Invalid "+list[idx].label);
						return;
					}
				}
			}
			if (sendLabel) {
				if (list[idx].hasOwnProperty("labelvalue")) {
					payload[list[idx].name] = {
						"label": list[idx].labelvalue,
						"value": list[idx].value,
						"type": tmptype,
					}
				} else {
					payload[list[idx].name] = {
						"label": list[idx].label,
						"value": list[idx].value,
						"type": tmptype,
					}
				}
			} else {
				payload[list[idx].name] = list[idx].value;
				if (list[idx].hasOwnProperty("labelvalue")) {
					payload[list[idx].labelname] = list[idx].labelvalue;
				}
			}
			idx++;
		}
		setLoading(true);
		setMessage("Please wait...")
		requestJSON.send(submitURL, "POST", payload).then(response => {
			if (response === "Send OK") {
				setMessage("Message Sent. Thank you!");
				clearInput(null);
			} else {
				setMessage("Error while sending. Please try again");
			}
			setLoading(false);
		});
	}

	function clearInput(e) {
		var idx = 0;
		var list = [...inputList]
		while (idx < list.length) {
			list[idx].value = "";
			if (list[idx].hasOwnProperty("labelvalue")) {
				list[idx].labelvalue = "";
			}
			idx++;
		}
		setInputkey(""+Date.now());
		setInputdirty(false);
	}
	const handleInputChange = (e, index) => {
		var list = [...inputList];
		if (list[index].hasOwnProperty("filter")) {
			if (list[index].filter === "integer") {
				list[index].value = formatTools.filterInteger(e.target.value);
			} else {
				list[index].value = e.target.value;
			}
		} else {
			list[index].value = e.target.value;
		}

		setInputList(list);
		setInputdirty(true);
	};

	const handleLabelChange = (newvalue, index) => {
		var list = [...inputList];
		list[index].labelvalue = newvalue;
		setInputList(list);
		setInputdirty(true);
	}
	const handleSelectChange = (newvalue, index) => {
		var list = [...inputList];
		list[index].value = newvalue;
		setInputList(list);
		setInputdirty(true);
	}

	return(
		<div className={styles.contactformholder}>
			{inputList.map((entry, idx) => {
					if (entry.label.length<1) {
						return <></>
					}
					return <div key={"row"+idx+inputkey} className={styles.contactfieldrow}>
						<label key={"label"+idx+inputkey} className={styles.rowlabel}>
							{entry.hasOwnProperty("labeloptions") ?
								<ControlSelect disabled={loading} key={"labelselect"+idx+inputkey} list={entry.labeloptions} textNone={entry.label} handleChange={newval=>handleLabelChange(newval, idx)} />
							:
								entry.label
							}
						</label>
						<div key={"rowdata"+idx+inputkey} className={styles.rowfield}>
							{entry.hasOwnProperty("options") ?
								<ControlSelect disabled={loading} list={entry.options} textNone={entry.label} handleChange={newval=>handleSelectChange(newval, idx)} />
							:
								<input
									disabled={loading}
									key={"input"+idx+inputkey}
									className={styles.rowinput}
									type={entry.hasOwnProperty("type")? entry.type :"text"}
									name={entry.name}
									value={entry.value}
									onChange={e => handleInputChange(e, idx)}
								/>
							}
						</div>
					</div>
			})}
			<br/>
			<div className={styles.contactfieldrow}>
				<div className={styles.rowlabel}></div>
				<div className={styles.rowfield+" text-centered"}>
					<button disabled={loading||!inputdirty} className={"textbutton cancelbutton"} onClick={clearInput}>Clear</button>
					<span>&nbsp;&nbsp;&nbsp;</span>
					<button disabled={loading||!inputdirty} className={"textbutton actionbutton"} onClick={submitInput}>Submit</button>
					{message!==""&&<div>{message}</div>}
				</div>
			</div>
		</div>
	);
}


ContactForm.propTypes = {
	sendLabel: PropTypes.bool,
};

ContactForm.defaultProps = {
	sendLabel: false,
};

export default ContactForm;

