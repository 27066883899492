import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"

import Header from "../header/"
import Footer from "../footer/"
import Seo from "../seo"

import "./style.css"
import "../../styles/colors.css"
import "../../styles/header.css"
import "../../styles/footer.css"
import "../../styles/navbar.css"

const Layout = ({
		resizeHandler,
		children,
		pageDescription,
		customClass,
		activePath,
		fullPath,
		lockDisplayType,
		pageImage,
		pageTitle,
		mobileTitle,
		structuredData
	}) => {
	const [dimensions, setDimensions] = useState({ 
/*
			height: window.innerHeight,
			width: window.innerWidth,
*/
			initDisplayType: true,
			displayType: 3
	  })
	
	useEffect(() => {
		if (typeof window === 'undefined') return;
		function handleResize() {
			const minwidth = 500;	// in case soft keyboard issue, don't consider as portrait
			if (dimensions.initDisplayType === true || lockDisplayType === false) {
				const newtype = (window.innerHeight<=0) ? 0: 
					(window.innerWidth >= minwidth && window.innerWidth/window.innerHeight)>1.5 ? 0:		
					(window.innerWidth >= minwidth && window.innerWidth/window.innerHeight)>1 ? 1:		// square
					(window.innerWidth/window.innerHeight)>0.8 ? 2:3								// Portrait
				setDimensions({
					/*
						height: window.innerHeight,
						width: window.innerWidth,
					*/
					initDisplayType: false,
					displayType: newtype
				});
				if (typeof resizeHandler !== 'undefined') {
					resizeHandler(newtype);
				}
			}
		}
		if (dimensions.initDisplayType === true) {
			handleResize();
		}
		window.addEventListener('resize', handleResize)
		return _ => {
			window.removeEventListener('resize', handleResize)
		}
	})
	return <>
			<Seo title={pageTitle} description={pageDescription} image={pageImage} canonicalpath={activePath} structuredData={structuredData} />
			<Header customClass={customClass} mobileTitle={mobileTitle} initDisplayType={dimensions.initDisplayType} displayType={dimensions.displayType} placeHolder="true" pageTitle={pageTitle} />
			<Header customClass={customClass} mobileTitle={mobileTitle} initDisplayType={dimensions.initDisplayType} displayType={dimensions.displayType} activePath={activePath} fullPath={fullPath!==''?fullPath:activePath} pageTitle={pageTitle} />
			<div>
			<main>{children?children:<p class="underconstruction font-size-large">
						{pageTitle} is Under Construction
			</p>}</main>
			</div>
			<Footer initDisplayType={dimensions.initDisplayType} displayType={dimensions.displayType} activePath={activePath} />
		</>
}


Layout.propTypes = {
	children: PropTypes.node,
	pageDescription: PropTypes.string,
	customClass: PropTypes.string,
	activePath: PropTypes.string,
	fullPath: PropTypes.string,
	lockDisplayType: PropTypes.bool,
	pageTitle: PropTypes.string,
	pageImage: PropTypes.string,
	mobileTitle: PropTypes.bool,
}


Layout.defaultProps = {
	pageDescription: ``,
	customClass: ``,
	activePath: ``,
	fullPath: ``,
	lockDisplayType: false,
	pageTitle: ``,
	pageImage: ``,
	mobileTitle: false
}


export default Layout
