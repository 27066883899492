import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import NavBarMenu from "../navbar/navbarmenu"
import "./style.css"

import logo from "../../images/logo.png"

import menulist from "../../content/menulist.json"

const Header = ({ displayType, activePath, fullPath, pageTitle, placeHolder, initDisplayType, mobileTitle, customClass }) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
	<header className={placeHolder?"header-placeholder":"header-freeze"}>
		<div className="header-background">
			<Link to="/" className="header-logo">
				<img src={logo} alt={data.site.siteMetadata.title} />
			</Link>
			{ (initDisplayType===false && menulist.length > 0) && <>
				{ placeHolder===false ? 
					<NavBarMenu displayType={displayType} items={menulist} type="header" activePath={activePath} fullPath={fullPath} />
				: <NavBarMenu displayType={displayType} items={menulist} type="header" />
				}
			</>}
		</div>
	</header>
	)
}

Header.propTypes = {
	customClass: PropTypes.string,
	displayType: PropTypes.number,
	activePath: PropTypes.string,
	fullPath: PropTypes.string,
	pageTitle: PropTypes.string,
	placeHolder: PropTypes.bool,
	mobileTitle: PropTypes.bool,
	initDisplayType: PropTypes.bool
}

Header.defaultProps = {
	customClass: ``,
	displayType: 3,
	placeHolder: false,
	activePath: ``,
	fullPath: ``,
	pageTitle: ``,
	mobileTitle: false,
	initDisplayType: false
}

export default Header
