// extracted by mini-css-extract-plugin
export var bottombanner = "index-module--bottombanner--2e5bc";
export var mainaltbgcolor = "index-module--mainaltbgcolor--b7c9a";
export var mainbackholder = "index-module--mainbackholder--45fa2";
export var mainbgcolor = "index-module--mainbgcolor--a217a";
export var maincontent = "index-module--maincontent--80b79";
export var mainholder = "index-module--mainholder--9b742";
export var mainlistholder = "index-module--mainlistholder--db2d5";
export var mainlogo = "index-module--mainlogo--b2d45";
export var mainlogoholder = "index-module--mainlogoholder--b7811";
export var mainlogomargin = "index-module--mainlogomargin--a648d";
export var mainlogomidmargin = "index-module--mainlogomidmargin--4b773";
export var mainsubsection = "index-module--mainsubsection--1e2ed";
export var mainsubsectionmargin = "index-module--mainsubsectionmargin--a224c";
export var topbanner = "index-module--topbanner--71541";
export var topbannergap = "index-module--topbannergap--eebcd";