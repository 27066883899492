import React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


import ContactForm from "../components/contactform"

import * as styles from "../styles/pages/index.module.css"

import imgbox from "../images/boxholder.png"

import list from "../content/careerslist.json"

const imgbanner = "../images/careers/banner.jpg"

const formatTools = require("../../lib/formatTools");

const CareersPage = () => {
	const curdate = formatTools.getDateStr(0);
	const contactfields = [
		{
			"name": "formlastname",
			"label": "Last Name",
			"value": "",
			"required": true,
		},
		{
			"name": "formfirstname",
			"label": "First Name",
			"value": "",
			"required": true,
		},
		{
			"name": "formmiddlename",
			"label": "Middle Name",
			"value": "",
		},
		{
			"name": "formposition",
			"label": "Position",
			"value": "",
			"required": true,
		},
		{
			"name": "formemail",
			"label": "Email",
			"type": "email",
			"value": "",
			"required": true,
		},
		{
			"name": "formmobile",
			"label": "Mobile",
			"type": "tel",
			"value": "",
			"required": true,
		},
		{
			"name": "formmsg",
			"label": "Brief Introduction",
			"value": "",
			"required": true,
		}
	];
	var numactive = 0;
	var tmpidx = 0;
	while (tmpidx < list.length) {
		if (!list[tmpidx].hasOwnProperty("jobpostend")) {
			numactive++;
		} else if (list[tmpidx].jobpostend >= curdate) {
			numactive++;
		}
		tmpidx++;
	}
	return (
			<Layout activePath='careers' pageDescription={"Join our team! We welcome new ideas and perspectives. Let us work together to improve things."} pageTitle="Careers">
				<StaticImage layout={"fullWidth"} className="fullblock" src={imgbanner} alt="Careers at IAMNET" />
				<div className={styles.mainsubsection+" "+styles.mainaltbgcolor+" text-centered"}>
					<img className={styles.mainlogomargin} src={imgbox} alt={""} />
					<h1 className={styles.mainaltbgcolor+" font-size-medium"}>
						Join our team
					</h1>
					<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
					<div className={styles.mainsubsectionmargin}>
						<div>We welcome new ideas and perspectives.</div>
						Let us work together to improve things.
					</div>
					<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				</div>
				<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
					<img className={styles.mainlogomargin} src={imgbox} alt={""} />
					<h2 className={styles.mainbgcolor+" font-size-medium"}>Opportunities at IAMNET</h2>
					<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
					<ul className={styles.mainlistholder}>
						{numactive > 0 ?
							list.map((entry,idx)=> {
								if (entry.hasOwnProperty("jobpostend")) {
									if (entry.jobpostend < curdate) {
										return <></>
									}
								}
								return <li style={{"textDecoration":"underline"}}><Link to={"/careers/"+entry.url}>{entry.jobname}</Link> </li>
							})
						:
							<span>No open positions at the moment</span>
						}

					</ul>
					<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				</div>
				<div className={styles.mainsubsection+" "+styles.mainbgcolor+" text-centered"}>
					<hr/>
					<img className={styles.mainlogomargin} src={imgbox} alt={""} />
					<h2 className={styles.mainbgcolor}>We're always looking:</h2>
					<img className={styles.mainlogomidmargin} src={imgbox} alt={""} />
					<ContactForm
							submitURL={"https://webform.api.iamnet.ph/iamnethr"}
							fields={contactfields}
							sendLabel={true}
						/>
					<img className={styles.mainlogomargin} src={imgbox} alt={""} />
				</div>

			</Layout>
		)

}


export default CareersPage
