
const validateEmail = (curstring) => {
	return validateString(curstring, /^[\w\-.+]+@[a-zA-Z0-9.-]+\.[a-zA-z0-9]{2,4}$/);
}

const validateMobile = (curstring) => {
	return validateString(curstring, /^[+]?[().\s0-9,;-]*$/);
}

const validateString = (curstring, regexstr) => {
	if(curstring.match(regexstr)){
		return true;
	}
	return false;
}

const filterNumeric = (num) => {
	var tmpnum = ""+num;
	return tmpnum.replace(/[^0-9.\-+]/g, '');
}

const filterInteger = (num) => {
	var tmpnum = ""+num;
	return tmpnum.replace(/[^0-9\-+]/g, '');
}

const integer = (num) => {
	return parseInt(numeric(num), 10)
}

const numeric = (num) => {
	var tmpnum = filterNumeric(num);
	if (tmpnum.length < 1)
		return 0;
	if (isNaN(parseFloat(tmpnum))) {
		return 0;
	}
	return parseFloat(tmpnum);
}


const currency = (num) => {
	//https://stackoverflow.com/questions/55556221/how-do-you-format-a-number-to-currency-when-using-react-native-expo
	return numeric(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const getDateStr = (tzoffsethr) => {
	var utcdate = new Date();
	var tzoffsetfactor = 3600000;
	var tzoffsetms = 0;
	if(typeof tzoffsethr === "undefined") {
		tzoffsetms = 8*tzoffsetfactor; // GMT+8; Asia/Manila
	} else {
		tzoffsetms = tzoffsethr*tzoffsetfactor;
	}
	var localdate = new Date(utcdate.getTime()+tzoffsetms);
	var tmpstr = localdate.toISOString();
	return tmpstr.replace("T"," ").substring(0,19);
}

module.exports = {
	getDateStr,
	validateEmail,
	validateMobile,
	filterInteger,
	filterNumeric,
	currency,
	numeric,
	integer
}

